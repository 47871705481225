<template>
    <div class="member-card" v-if="!!dictionaryData">
        <p class="title">{{dictionaryData.title}}</p>
        <div class="cards-box">
            <div class="cards-total" :class="dictionaryData.title=='冻结金额'?'frozen-box':''">
                <span class="total-label">{{dictionaryData.totalLabel}}</span>
                <span class="total-num">{{dictionaryData.total}}</span>
                <span class="total-time">{{dictionaryData.time}}</span>
                <!-- valueKbit -->
            </div>

            <div class="cards-detail" v-show="dictionaryData.title!=='冻结金额'">
                <div class="detail-lt">
                    <p class="detail-label">{{dictionaryData.realLabel}}</p>
                    <p class="detail-num">{{dictionaryData.real}}</p>
                    <!-- valueKbit -->
                </div>
                <div class="detail-rt">
                    <p class="detail-label">{{dictionaryData.giftLabel}}</p>
                    <p class="detail-num">{{dictionaryData.gift}}</p>
                    <!-- valueKbit -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name:"member-card",
        props:{
			dictionaryData:Object
        },
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";
    .member-card{
        background:#fff;
        padding-bottom:10px;
        margin-top:20px;
        .title{
            font-size:32px;
            color:#333;
            line-height:1;
            padding:42px 30px;
            font-weight:600;
        }
        .cards-box{
            margin:5px 30px 30px;
            .cards-total{
                position: relative;
                display:flex;
                justify-content:space-between;
                padding: 48px 35px;
                line-height:1;
                color:#000;
                background:rgb(210,238,255);
                border-radius: $radius-box $radius-box 0 0;
                .total-label{
                    font-size:28px;
                    flex:1;
                }
                .total-num{
                    flex:1;
                    display:inline-block;
                    padding-left:80px;
                    font-size:38px;
                }
                .total-time{
                  position: absolute;
                  bottom: 10px;
                  font-size: 24px;
                  color: #9BA0AC;
                }
            }
            .frozen-box{
                border-radius: $radius-box;
            }
            .cards-detail{
                display:flex;
                justify-content:space-between;
                border-radius: 0 0 $radius-box $radius-box;
                border: #ccc 1px solid;
                border-top: none;
                padding:24px 30px;
                .detail-lt{
                    flex:1;
                    border-right: #e8e8e8 2px solid;
                }
                .detail-rt{
                    flex:1;
                    padding-left: 80px;
                }
                .detail-label{
                    font-size:28px;
                    color:$light-gray-color;
                }
                .detail-num{
                    font-size:34px;
                    font-size:#000;
                }
            }
        }
    }
</style>
